export const UserRole = {
  Admin: 'Admin',
  Editor: 'Operator',
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'np', name: 'Nepali', direction: 'ltr' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  id: 1,
  title: 'Admin',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const unitOfMeasureOptions = [
  {
    label: 'Box (बक्सा)',
    value: 'Box',
  },
  {
    label: 'Bora (बोरा)',
    value: 'Bora',
  },
  {
    label: 'Pieces (एउटा)',
    value: 'Pieces',
  },
  {
    label: 'Dozen (दर्जन)',
    value: 'Dozen',
  },
  {
    label: 'Pair (जोडी)',
    value: 'Pair',
  },
  {
    label: 'Complete Set (पूरा सेट)',
    value: 'Complete Set',
  },
  {
    label: 'Kg',
    value: 'Kg',
  },
];

export const unitOfMeasureLOptions = [
  {
    label: 'Box',
    value: 'बक्सा',
  },
  {
    label: 'Bora',
    value: 'बोरा',
  },
  {
    label: 'Pieces',
    value: 'एउटा',
  },
  {
    label: 'Dozen',
    value: 'दर्जन',
  },
  {
    label: 'Pair',
    value: 'जोडी',
  },
  {
    label: 'Complete Set',
    value: 'पूरा सेट',
  },
  {
    label: 'Kg',
    value: 'Kg',
  },
];
