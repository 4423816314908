import { toast } from 'react-toastify';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status === 401) {
    if (window.location.pathname !== '/login') {
      localStorage.clear();
      window.location.replace('/user/login');
    }
  }

  if (response.status === 500) {
    response.json().then((data) => {
      if (data.error.message) {
        toast.error(data.error.message);
        throw new Error('Something went wrong.');
      }
      toast.error('Something went wrong.');
      throw new Error('Something went wrong.');
    });
    throw new Error('Something went wrong.');
  }
  return response;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */

export default function request(url, options) {
  const isAppUrl = /^\//.test(url);
  if (isAppUrl) {
    const data = fetch(`https://api-rms.ndrrma.gov.np/api${url}`, options)
      .then(checkStatus)
      .then(parseJSON);
    return data;
  }
  if (options['Content-Type'] === 'text/html') {
    return fetch(url, options).then((response) => response.text());
  }
  return fetch(url, options).then(parseJSON);
}
